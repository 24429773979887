import { Row, Col, Card } from 'antd';
import Colors from '../config/pallete';
import { Link, useNavigate } from 'react-router-dom';
import openingHoursLogo from '../assets/icons/opening-hours.svg';
import airplaneLogo from '../assets/icons/airplane.svg';
import { useSelector, useDispatch } from 'react-redux'
import forestLogo from '../assets/icons/forest.svg';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import SavingResultFile from '../output/pdf/SavingResultFile';
import SavingResult from '../output/pdf/SavingResult';
import ReactPDF from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { calculateSaving, downloadURL } from '../utils/calculator';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


function Result() {

  const contact = useSelector((state) => state.contact.value);
  const outlet = useSelector((state) => state.outlet.value);
  const [isLoading, setLoading] = useState(false);

  const data = calculateSaving({ contact, outlet })


   const formatCurrency = (currency) => {
  let formatDollar = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  // Remove the currency symbol.
  return formatDollar.format(currency || 0).split('$')[1];
}


  useEffect(() => {
    async function mail() {
      const formData = new FormData();
      formData.append(
        "file",
        await pdf(<SavingResult data={data} />).toBlob(),
        "details_report.pdf"
      );
      formData.append("salesPerson", data.contact.sales_contact);
      formData.append("receipt", data.contact.email);
      await fetch("http://localhost:3001/api/email", {
        method: 'POST',
        body: formData
      })
    }
    // mail()
  }, [])

  return (
    <div>
      <div className='mt-10 text-left'>
        <Card size="small" title="Outlet Savings Per Annum" headStyle={{ backgroundColor: Colors.tp_dark_blue, color: Colors.white, fontSize: 28 }} bodyStyle={{ border: '1px solid', borderColor: Colors.tp_dark_blue }}>
          <Row>
            <Col className='md:w-1/3 w-full' >
              <h1 className='head_text'>
                ${formatCurrency(data.outletSavings.perAnnum.costSavings)}
              </h1>
              <p className='orange_bold'>Cost Savings</p>
            </Col>
            <Col className='md:w-1/3 w-full'>
              <h1 className='head_text'>
                {formatCurrency(data.outletSavings.perAnnum.energySavings)}
                <span className='text_3'>kWh</span>
              </h1>
              <p className='orange_bold'>Energy Savings</p>
            </Col>
          </Row>
        </Card>
        <br />

        <Card size="small" title="Group Savings Per Annum" headStyle={{ backgroundColor: Colors.tp_dark_blue, color: Colors.white, fontSize: 28 }} bodyStyle={{ border: '1px solid', borderColor: Colors.tp_dark_blue }}>
          <Row>
            <Col className='md:w-1/3 w-full' >
              <h1 className='head_text'>
                ${formatCurrency(data.groupSavings.costSavings)}
              </h1>
              <p className='orange_bold'>Cost Savings</p>
            </Col>
            <Col className='md:w-1/3 w-full'>
              <h1 className='head_text'>
                {formatCurrency(data.groupSavings.energySavings)}
                <span className='text_3'>kWh</span>
              </h1>
              <p className='orange_bold'>Energy Savings</p>
            </Col>
          </Row>
          <Row>
            <Col className='md:w-1/3 w-full mt-10' >
              <Col className='max-w-fit'>
                <img className='mx-auto' src={openingHoursLogo} width={100} height={100} alt='opening-hours' />
                <h1 className='head_text'>
                  {formatCurrency(data.groupSavings.mealsServed)}
                </h1>
              </Col>
              <p className='orange_bold'>Meals Served</p>
              <p className='orange_small'>Equivalent to operating {formatCurrency(data.groupSavings.noOfServiceDays)} more days.</p>
            </Col>
            <Col className='md:w-1/3 w-full mt-10'>
              <Col className='max-w-fit'>
                <img className='mx-auto' src={airplaneLogo} width={100} height={100} alt='airplane' />
                <h1 className='head_text'>
                  {formatCurrency(data.groupSavings.kgOfCO2)}
                </h1>
              </Col>
              <p className='orange_bold'>kg of CO<sub>2</sub></p>
              <p className='orange_small'>Equivalent to {formatCurrency(data.groupSavings.noOfCarsDrivenForAYear)} trips around the world on a Boeing 747.</p>
            </Col>
            <Col className='md:w-1/3 w-full mt-10'>
              <Col className='max-w-fit'>
                <img className='mx-auto' src={forestLogo} width={100} height={100} alt='forest' />
                <h1 className='head_text'>
                  {formatCurrency(data.groupSavings.treesPlanted)}
                </h1>
              </Col>
              <p className='orange_bold'>Trees Planted</p>
              <p className='orange_small'>Equivalent to {data.groupSavings.noOfSoccerFields} soccer fields.</p>
            </Col>
          </Row>
        </Card>
        <br />

        <Card size="small" title="Group Savings Over Equipment(s) Lifetime" headStyle={{ backgroundColor: Colors.tp_dark_blue, color: Colors.white, fontSize: 28 }} bodyStyle={{ border: '1px solid', borderColor: Colors.tp_dark_blue }}>
          <Row>
            <Col className='md:w-1/3 w-full' >
              <h1 className='head_text'>
                ${formatCurrency(data.outletSavings.overEquipmentLifetime.costSavings)}
              </h1>
              <p className='orange_bold'>Cost Savings</p>
            </Col>
            <Col className='md:w-1/3 w-full'>
              <h1 className='head_text'>
                {formatCurrency(data.outletSavings.overEquipmentLifetime.energySavings)}
                <span className='text_3'>kWh</span>
              </h1>
              <p className='orange_bold'>Energy Savings</p>
            </Col>
          </Row>
        </Card>

        <p className='dark_blue_small mt-[10px]'>Figures represented in this report are an estimate and may differ from the actual savings generated.</p>
        <Row className='mt-10 justify-end'>
          <Link to={'/contact-details'}><button className='plain_btn_outline'>Back</button></Link>

          {/* <Link to={'/result/pdf'} target='_blank'> */}
          {/* <PDFDownloadLink fileName='TablePointer - Energy Saving Projection' document={<SavingResultFile data={data} />} >
            <button className='primary_btn ml-2' onClick={()=>downloadURL(data)}>Download Report</button>
          </PDFDownloadLink> */}
          <button className='primary_btn ml-2' onClick={() => downloadURL(data, setLoading)}>
            {isLoading ? <Spin indicator={<LoadingOutlined
            style={{
              fontSize: 19.5,
              width: `120px`,
            }}
            spin
          />} /> : `Download Report`}</button>

          {/* </Link> */}
        </Row>
      </div>
    </div>
  )
}

export default Result