import axios from "axios"

//to input value from zac, each equipment + restaurant type's energy saved per hour
const QUALITIES = {
  food_court: {
    exhaust: 4.761904762,
    fridge: 0.625,
    ac: 1.587301587,
    meals: 400
  },
  full_service: {
    exhaust: 3.428571429,
    fridge: 0.625,
    ac: 1.587301587,
    meals: 360
  },
  fast_food: {
    exhaust: 3.80952381,
    fridge: 0.625,
    ac: 1.587301587,
    meals: 400
  },
  quick_service: {
    exhaust: 2.666666667,
    fridge: 0.625,
    ac: 1.587301587,
    meals: 280
  },
  cafe: {
    exhaust: 1.904761905,
    fridge: 0.625,
    ac: 1.587301587,
    meals: 200
  },
  kiosk: {
    exhaust: 1.333333333,
    fridge: 0.625,
    ac: 1.587301587,
    meals: 140
  },
}
//Used for number of service days sustainbility calculation
const restaurantTypeDailyAverage = {
  food_court: 500,
  full_service: 360,
  fast_food: 400,
  quick_service: 280,
  cafe: 200,
  kiosk: 140,
}

// Sum of all equipment Kwh
const energySaved = (outlet) => {
  let res = 0
  const quality = QUALITIES[outlet.restaurant_type]
  console.log('quality ***')
  console.log(quality)
  console.log('outlet ***')
  console.log(outlet)
  if (outlet.exhaust_number !== 0) {
    res += (quality.exhaust * outlet.exhaust_number)
    console.log('exhaust calc ****')
    console.log(quality.exhaust * outlet.exhaust_number)
  }
  if (outlet.ac_number !== 0) {
    res += (quality.ac * outlet.ac_number)
    console.log('ac calc ****')
    console.log((quality.fridge * outlet.ac_number))
    console.log(quality.exhaust * outlet.exhaust_number)
  }
  if (outlet.fridge_number !== 0) {
    console.log('fridge calc ***')
    console.log(quality.ac * outlet.fridge_number)
    res += (quality.fridge * outlet.fridge_number)
  }


  return res
}

// unsure what below is for
const equipementSum = (outlet) => {
  let res = 0;
  res += (outlet.exhaust_number * outlet.exhaust_width)
  res += outlet.fridge_number
  res += outlet.ac_number
  return res;
}


export const calculateSaving = (data) => {
  console.log('input test case ***')
  console.log(data)

  // const perAnnum = {
  //     //should be referencing energySavings instead of energySaved
  //     costSavings: energySaved(data.outlet) * data.outlet.tariff,
  //
  //     // should be referencing energySaved instead of equipmentSum
  //     energySavings: equipementSum(data.outlet) * data.outlet.operating_hours * 52
  // }
  const temp = energySaved(data.outlet) * data.outlet.operating_hours * 52;
  console.log('temp ***')
  console.log(temp)
  const perAnnum = {
    // should be referencing energySaved instead of equipmentSum, 52 reference to the 52 weeks in a year
    //energySavings: equipementSum(data.outlet) * data.outlet.operating_hours * 52,
    energySavings: temp,

    //should be referencing energySavings instead of energySaved
    //costSavings: energySaved(data.outlet) * data.outlet.tariff
    costSavings: temp * data.outlet.tariff

  }
  console.log('perAnnum ***')
  console.log(perAnnum)

  // 5 = years to be considered equipment lifetime
  const overEquipmentLifetime = {
    costSavings: perAnnum.costSavings * 5,
    energySavings: perAnnum.energySavings * 5,
  }
  // assuming below is group savings
  const costSavings = overEquipmentLifetime.costSavings * data.outlet.total_outlets
  const energySavings = overEquipmentLifetime.energySavings * data.outlet.total_outlets
  const mealsServed = (perAnnum.costSavings * data.outlet.total_outlets * 2) 
  // / QUALITIES[data.outlet.restaurant_type].meals // need to be modified 
  const kgOfCO2 = (perAnnum.energySavings * data.outlet.total_outlets) * 0.709
  const treesPlanted = kgOfCO2 * 0.017

  const salesPersonsEmail = [
    { name: 'matthieu_quantin', email: 'matt.quentin@tablepointer.com' },
    { name: 'danny_koh', email: 'danny.koh@tablepointer.com' },
    { name: 'faye_ong', email: 'faye.ong@tablepointer.com' },
    { name: 'takako_sasamori', email: 'takako.sasamori@tablepointer.com' },
  ];

  let sales_contact = salesPersonsEmail.filter(obj => obj.name == data.contact.sales_contact)[0].name;
  console.log('sales_contact ***')
  console.log(sales_contact)
  sales_contact = sales_contact.split('_').map(str => (str.charAt(0).toUpperCase() + str.slice(1))).join(' ')
  console.log('sales_contact ***')
  console.log(sales_contact) 

  const response = {
    contact: {
      ...data.contact,
      sales_contact
    },
    sales_person_email: salesPersonsEmail.filter(obj => obj.name == data.contact.sales_contact)[0].email,
    outlet: {
      ...data.outlet,
      restaurant_type: data.outlet.restaurant_type.split('_').map(str => (str.charAt(0).toUpperCase() + str.slice(1))).join(' '),
      ac_controller: data.outlet.ac_controller ? data.outlet.ac_controller.map(val => val.split('_').map(str => (str.charAt(0).toUpperCase() + str.slice(1))).join(' ')) : '',
      ac_type: data.outlet.ac_type ? data.outlet.ac_type.map(type => type.split('_').map(str => (str.charAt(0).toUpperCase() + str.slice(1))).join(' ')): '',
      fridge_type: data.outlet.fridge_type? data.outlet.fridge_type.map(type => type.split('_').map(str => (str.charAt(0).toUpperCase() + str.slice(1))).join(' ')): '',
    },
    outletSavings: {
      perAnnum: {
        costSavings: Math.round(perAnnum.costSavings),
        energySavings: Math.round(perAnnum.energySavings),
      },
      overEquipmentLifetime: {
        costSavings: Math.round(costSavings), // Math.round(overEquipmentLifetime.costSavings),
        energySavings: Math.round(energySavings), // Math.round(overEquipmentLifetime.energySavings),
      }
    },
    groupSavings: {
      costSavings: Math.round(perAnnum.costSavings * data.outlet.total_outlets),
      energySavings: Math.round(perAnnum.energySavings * data.outlet.total_outlets),
      mealsServed: Math.round(mealsServed),
      kgOfCO2: Math.round(kgOfCO2),
      treesPlanted: Math.round(treesPlanted),
      //below are fine print sustainability stats
      noOfServiceDays: Math.round(mealsServed / restaurantTypeDailyAverage[data.outlet.restaurant_type]),
      noOfCarsDrivenForAYear: Math.round(((kgOfCO2 / 220975) + Number.EPSILON) * 100) / 100, // trips
      // Math.round(kgOfCO2 / 220975),
      noOfSoccerFields: Math.round(treesPlanted / 880) // boeing trees planted
    },
    input: data
  }

  console.log('response ****')
  console.log(response)
  console.log(JSON.stringify(response))

  return response;
}

export const downloadURL = async (data, callBack) => {
  callBack(true);
  const response = await axios.post(
    process.env.REACT_APP_TP_ADMIN_DOWNLOAD_URL,
    data,
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
      }
    }
  );

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'TablePointer - Energy Saving Projection.pdf'); //or any other extension
  document.body.appendChild(link);
  link.click();
  callBack(false);
}