const Countries = [
    { no: 1, label: 'Afghanistan', code: 'AF', dial: '+93' },
    { no: 2, label: 'Albania', code: 'AL', dial: '+355' },
    { no: 3, label: 'Algeria', code: 'DZ', dial: '+213' },
    { no: 4, label: 'American Samoa', code: 'AS', dial: '+1684' },
    { no: 5, label: 'Andorra', code: 'AD', dial: '+376' },
    { no: 6, label: 'Angola', code: 'AO', dial: '+244' },
    { no: 7, label: 'Anguilla', code: 'AI', dial: '+1264' },
    { no: 8, label: 'Antarctica', code: 'AQ', dial: '+672' },
    { no: 9, label: 'Antigua and Barbuda', code: 'AG', dial: '+1268' },
    { no: 10, label: 'Argentina', code: 'AR', dial: '+54' },
    { no: 11, label: 'Armenia', code: 'AM', dial: '+374' },
    { no: 12, label: 'Aruba', code: 'AW', dial: '+297' },
    { no: 13, label: 'Australia', code: 'AU', dial: '+61' },
    { no: 14, label: 'Austria', code: 'AT', dial: '+43' },
    { no: 15, label: 'Azerbaijan', code: 'AZ', dial: '+994' },
    { no: 16, label: 'Bahamas', code: 'BS', dial: '+1242' },
    { no: 17, label: 'Bahrain', code: 'BH', dial: '+973' },
    { no: 18, label: 'Bangladesh', code: 'BD', dial: '+880' },
    { no: 19, label: 'Barbados', code: 'BB', dial: '+1246' },
    { no: 20, label: 'Belarus', code: 'BY', dial: '+375' },
    { no: 21, label: 'Belgium', code: 'BE', dial: '+32' },
    { no: 22, label: 'Belize', code: 'BZ', dial: '+501' },
    { no: 23, label: 'Benin', code: 'BJ', dial: '+229' },
    { no: 24, label: 'Bermuda', code: 'BM', dial: '+1441' },
    { no: 25, label: 'Bhutan', code: 'BT', dial: '+975' },
    { no: 26, label: 'Bolivia', code: 'BO', dial: '+591' },
    { no: 27, label: 'Bosnia and Herzegovina', code: 'BA', dial: '+387' },
    { no: 28, label: 'Botswana', code: 'BW', dial: '+267' },
    { no: 29, label: 'Brazil', code: 'BR', dial: '+55' },
    { no: 30, label: 'British Indian Ocean Territory', code: 'IO', dial: '+246' },
    { no: 31, label: 'British Virgin Islands', code: 'VG', dial: '+1284' },
    { no: 32, label: 'Brunei', code: 'BN', dial: '+673' },
    { no: 33, label: 'Bulgaria', code: 'BG', dial: '+359' },
    { no: 34, label: 'Burkina Faso', code: 'BF', dial: '+226' },
    { no: 35, label: 'Burundi', code: 'BI', dial: '+257' },
    { no: 36, label: 'Cambodia', code: 'KH', dial: '+855' },
    { no: 37, label: 'Cameroon', code: 'CM', dial: '+237' },
    { no: 38, label: 'Canada', code: 'CA', dial: '+1' },
    { no: 39, label: 'Cape Verde', code: 'CV', dial: '+238' },
    { no: 40, label: 'Cayman Islands', code: 'KY', dial: '+1345' },
    { no: 41, label: 'Central African Republic', code: 'CF', dial: '+236' },
    { no: 42, label: 'Chad', code: 'TD', dial: '+235' },
    { no: 43, label: 'Chile', code: 'CL', dial: '+56' },
    { no: 44, label: 'China', code: 'CN', dial: '+86' },
    { no: 45, label: 'Christmas Island', code: 'CX', dial: '+61' },
    { no: 46, label: 'Cocos Islands', code: 'CC', dial: '+61' },
    { no: 47, label: 'Colombia', code: 'CO', dial: '+57' },
    { no: 48, label: 'Comoros', code: 'KM', dial: '+269' },
    { no: 49, label: 'Cook Islands', code: 'CK', dial: '+682' },
    { no: 50, label: 'Costa Rica', code: 'CR', dial: '+506' },
    { no: 51, label: 'Croatia', code: 'HR', dial: '+385' },
    { no: 52, label: 'Cuba', code: 'CU', dial: '+53' },
    { no: 53, label: 'Curacao', code: 'CW', dial: '+599' },
    { no: 54, label: 'Cyprus', code: 'CY', dial: '+357' },
    { no: 55, label: 'Czech Republic', code: 'CZ', dial: '+420' },
    { no: 56, label: 'Democratic Republic of the Congo', code: 'CD', dial: '+243' },
    { no: 57, label: 'Denmark', code: 'DK', dial: '+45' },
    { no: 58, label: 'Djibouti', code: 'DJ', dial: '+253' },
    { no: 59, label: 'Dominica', code: 'DM', dial: '+1767' },
    { no: 60, label: 'Dominican Republic', code: 'DO', dial: '+1809' },
    { no: 61, label: 'Dominican Republic', code: 'DO', dial: '+1829' },
    { no: 62, label: 'Dominican Republic', code: 'DO', dial: '+1849' },
    { no: 63, label: 'East Timor', code: 'TL', dial: '+670' },
    { no: 64, label: 'Ecuador', code: 'EC', dial: '+593' },
    { no: 65, label: 'Egypt', code: 'EG', dial: '+20' },
    { no: 66, label: 'El Salvador', code: 'SV', dial: '+503' },
    { no: 67, label: 'Equatorial Guinea', code: 'GQ', dial: '+240' },
    { no: 68, label: 'Eritrea', code: 'ER', dial: '+291' },
    { no: 69, label: 'Estonia', code: 'EE', dial: '+372' },
    { no: 70, label: 'Ethiopia', code: 'ET', dial: '+251' },
    { no: 71, label: 'Falkland Islands', code: 'FK', dial: '+500' },
    { no: 72, label: 'Faroe Islands', code: 'FO', dial: '+298' },
    { no: 73, label: 'Fiji', code: 'FJ', dial: '+679' },
    { no: 74, label: 'Finland', code: 'FI', dial: '+358' },
    { no: 75, label: 'France', code: 'FR', dial: '+33' },
    { no: 76, label: 'French Polynesia', code: 'PF', dial: '+689' },
    { no: 77, label: 'Gabon', code: 'GA', dial: '+241' },
    { no: 78, label: 'Gambia', code: 'GM', dial: '+220' },
    { no: 79, label: 'Georgia', code: 'GE', dial: '+995' },
    { no: 80, label: 'Germany', code: 'DE', dial: '+49' },
    { no: 81, label: 'Ghana', code: 'GH', dial: '+233' },
    { no: 82, label: 'Gibraltar', code: 'GI', dial: '+350' },
    { no: 83, label: 'Greece', code: 'GR', dial: '+30' },
    { no: 84, label: 'Greenland', code: 'GL', dial: '+299' },
    { no: 85, label: 'Grenada', code: 'GD', dial: '+1473' },
    { no: 86, label: 'Guam', code: 'GU', dial: '+1671' },
    { no: 87, label: 'Guatemala', code: 'GT', dial: '+502' },
    { no: 88, label: 'Guernsey', code: 'GG', dial: '+441481' },
    { no: 89, label: 'Guinea', code: 'GN', dial: '+224' },
    { no: 90, label: 'Guinea-Bissau', code: 'GW', dial: '+245' },
    { no: 91, label: 'Guyana', code: 'GY', dial: '+592' },
    { no: 92, label: 'Haiti', code: 'HT', dial: '+509' },
    { no: 93, label: 'Honduras', code: 'HN', dial: '+504' },
    { no: 94, label: 'Hong Kong', code: 'HK', dial: '+852' },
    { no: 95, label: 'Hungary', code: 'HU', dial: '+36' },
    { no: 96, label: 'Iceland', code: 'IS', dial: '+354' },
    { no: 97, label: 'India', code: 'IN', dial: '+91' },
    { no: 98, label: 'Indonesia', code: 'ID', dial: '+62' },
    { no: 99, label: 'Iran', code: 'IR', dial: '+98' },
    { no: 100, label: 'Iraq', code: 'IQ', dial: '+964' },
    { no: 101, label: 'Ireland', code: 'IE', dial: '+353' },
    { no: 102, label: 'Isle of Man', code: 'IM', dial: '+441624' },
    { no: 103, label: 'Israel', code: 'IL', dial: '+972' },
    { no: 104, label: 'Italy', code: 'IT', dial: '+39' },
    { no: 105, label: 'Ivory Coast', code: 'CI', dial: '+225' },
    { no: 106, label: 'Jamaica', code: 'JM', dial: '+1876' },
    { no: 107, label: 'Japan', code: 'JP', dial: '+81' },
    { no: 108, label: 'Jersey', code: 'JE', dial: '+441534' },
    { no: 109, label: 'Jordan', code: 'JO', dial: '+962' },
    { no: 110, label: 'Kazakhstan', code: 'KZ', dial: '+7' },
    { no: 111, label: 'Kenya', code: 'KE', dial: '+254' },
    { no: 112, label: 'Kiribati', code: 'KI', dial: '+686' },
    { no: 113, label: 'Kosovo', code: 'XK', dial: '+383' },
    { no: 114, label: 'Kuwait', code: 'KW', dial: '+965' },
    { no: 115, label: 'Kyrgyzstan', code: 'KG', dial: '+996' },
    { no: 116, label: 'Laos', code: 'LA', dial: '+856' },
    { no: 117, label: 'Latvia', code: 'LV', dial: '+371' },
    { no: 118, label: 'Lebanon', code: 'LB', dial: '+961' },
    { no: 119, label: 'Lesotho', code: 'LS', dial: '+266' },
    { no: 120, label: 'Liberia', code: 'LR', dial: '+231' },
    { no: 121, label: 'Libya', code: 'LY', dial: '+218' },
    { no: 122, label: 'Liechtenstein', code: 'LI', dial: '+423' },
    { no: 123, label: 'Lithuania', code: 'LT', dial: '+370' },
    { no: 124, label: 'Luxembourg', code: 'LU', dial: '+352' },
    { no: 125, label: 'Macau', code: 'MO', dial: '+853' },
    { no: 126, label: 'Macedonia', code: 'MK', dial: '+389' },
    { no: 127, label: 'Madagascar', code: 'MG', dial: '+261' },
    { no: 128, label: 'Malawi', code: 'MW', dial: '+265' },
    { no: 129, label: 'Malaysia', code: 'MY', dial: '+60' },
    { no: 130, label: 'Maldives', code: 'MV', dial: '+960' },
    { no: 131, label: 'Mali', code: 'ML', dial: '+223' },
    { no: 132, label: 'Malta', code: 'MT', dial: '+356' },
    { no: 133, label: 'Marshall Islands', code: 'MH', dial: '+692' },
    { no: 134, label: 'Mauritania', code: 'MR', dial: '+222' },
    { no: 135, label: 'Mauritius', code: 'MU', dial: '+230' },
    { no: 136, label: 'Mayotte', code: 'YT', dial: '+262' },
    { no: 137, label: 'Mexico', code: 'MX', dial: '+52' },
    { no: 138, label: 'Micronesia', code: 'FM', dial: '+691' },
    { no: 139, label: 'Moldova', code: 'MD', dial: '+373' },
    { no: 140, label: 'Monaco', code: 'MC', dial: '+377' },
    { no: 141, label: 'Mongolia', code: 'MN', dial: '+976' },
    { no: 142, label: 'Montenegro', code: 'ME', dial: '+382' },
    { no: 143, label: 'Montserrat', code: 'MS', dial: '+1664' },
    { no: 144, label: 'Morocco', code: 'MA', dial: '+212' },
    { no: 145, label: 'Mozambique', code: 'MZ', dial: '+258' },
    { no: 146, label: 'Myanmar', code: 'MM', dial: '+95' },
    { no: 147, label: 'Namibia', code: 'NA', dial: '+264' },
    { no: 148, label: 'Nauru', code: 'NR', dial: '+674' },
    { no: 149, label: 'Nepal', code: 'NP', dial: '+977' },
    { no: 150, label: 'Netherlands', code: 'NL', dial: '+31' },
    { no: 151, label: 'Netherlands Antilles', code: 'AN', dial: '+599' },
    { no: 152, label: 'New Caledonia', code: 'NC', dial: '+687' },
    { no: 153, label: 'New Zealand', code: 'NZ', dial: '+64' },
    { no: 154, label: 'Nicaragua', code: 'NI', dial: '+505' },
    { no: 155, label: 'Niger', code: 'NE', dial: '+227' },
    { no: 156, label: 'Nigeria', code: 'NG', dial: '+234' },
    { no: 157, label: 'Niue', code: 'NU', dial: '+683' },
    { no: 158, label: 'North Korea', code: 'KP', dial: '+850' },
    { no: 159, label: 'Northern Mariana Islands', code: 'MP', dial: '+1670' },
    { no: 160, label: 'Norway', code: 'NO', dial: '+47' },
    { no: 161, label: 'Oman', code: 'OM', dial: '+968' },
    { no: 162, label: 'Pakistan', code: 'PK', dial: '+92' },
    { no: 163, label: 'Palau', code: 'PW', dial: '+680' },
    { no: 164, label: 'Palestine', code: 'PS', dial: '+970' },
    { no: 165, label: 'Panama', code: 'PA', dial: '+507' },
    { no: 166, label: 'Papua New Guinea', code: 'PG', dial: '+675' },
    { no: 167, label: 'Paraguay', code: 'PY', dial: '+595' },
    { no: 168, label: 'Peru', code: 'PE', dial: '+51' },
    { no: 169, label: 'Philippines', code: 'PH', dial: '+63' },
    { no: 170, label: 'Pitcairn', code: 'PN', dial: '+64' },
    { no: 171, label: 'Poland', code: 'PL', dial: '+48' },
    { no: 172, label: 'Portugal', code: 'PT', dial: '+351' },
    { no: 173, label: 'Puerto Rico', code: 'PR', dial: '+1787' },
    { no: 174, label: 'Puerto Rico', code: 'PR', dial: '+1939' },
    { no: 175, label: 'Qatar', code: 'QA', dial: '+974' },
    { no: 176, label: 'Republic of the Congo', code: 'CG', dial: '+242' },
    { no: 177, label: 'Reunion', code: 'RE', dial: '+262' },
    { no: 178, label: 'Romania', code: 'RO', dial: '+40' },
    { no: 179, label: 'Russia', code: 'RU', dial: '+7' },
    { no: 180, label: 'Rwanda', code: 'RW', dial: '+250' },
    { no: 181, label: 'Saint Barthelemy', code: 'BL', dial: '+590' },
    { no: 182, label: 'Saint Helena', code: 'SH', dial: '+290' },
    { no: 183, label: 'Saint Kitts and Nevis', code: 'KN', dial: '+1869' },
    { no: 184, label: 'Saint Lucia', code: 'LC', dial: '+1758' },
    { no: 185, label: 'Saint Martin', code: 'MF', dial: '+590' },
    { no: 186, label: 'Saint Pierre and Miquelon', code: 'PM', dial: '+508' },
    { no: 187, label: 'Saint Vincent and the Grenadines', code: 'VC', dial: '+1784' },
    { no: 188, label: 'Samoa', code: 'WS', dial: '+685' },
    { no: 189, label: 'San Marino', code: 'SM', dial: '+378' },
    { no: 190, label: 'Sao Tome and Principe', code: 'ST', dial: '+239' },
    { no: 191, label: 'Saudi Arabia', code: 'SA', dial: '+966' },
    { no: 192, label: 'Senegal', code: 'SN', dial: '+221' },
    { no: 193, label: 'Serbia', code: 'RS', dial: '+381' },
    { no: 194, label: 'Seychelles', code: 'SC', dial: '+248' },
    { no: 195, label: 'Sierra Leone', code: 'SL', dial: '+232' },
    { no: 196, label: 'Singapore', code: 'SG', dial: '+65' },
    { no: 197, label: 'Sint Maarten', code: 'SX', dial: '+1721' },
    { no: 198, label: 'Slovakia', code: 'SK', dial: '+421' },
    { no: 199, label: 'Slovenia', code: 'SI', dial: '+386' },
    { no: 200, label: 'Solomon Islands', code: 'SB', dial: '+677' },
    { no: 201, label: 'Somalia', code: 'SO', dial: '+252' },
    { no: 202, label: 'South Africa', code: 'ZA', dial: '+27' },
    { no: 203, label: 'South Korea', code: 'KR', dial: '+82' },
    { no: 204, label: 'South Sudan', code: 'SS', dial: '+211' },
    { no: 205, label: 'Spain', code: 'ES', dial: '+34' },
    { no: 206, label: 'Sri Lanka', code: 'LK', dial: '+94' },
    { no: 207, label: 'Sudan', code: 'SD', dial: '+249' },
    { no: 208, label: 'Suriname', code: 'SR', dial: '+597' },
    { no: 209, label: 'Svalbard and Jan Mayen', code: 'SJ', dial: '+47' },
    { no: 210, label: 'Swaziland', code: 'SZ', dial: '+268' },
    { no: 211, label: 'Sweden', code: 'SE', dial: '+46' },
    { no: 212, label: 'Switzerland', code: 'CH', dial: '+41' },
    { no: 213, label: 'Syria', code: 'SY', dial: '+963' },
    { no: 214, label: 'Taiwan', code: 'TW', dial: '+886' },
    { no: 215, label: 'Tajikistan', code: 'TJ', dial: '+992' },
    { no: 216, label: 'Tanzania', code: 'TZ', dial: '+255' },
    { no: 217, label: 'Thailand', code: 'TH', dial: '+66' },
    { no: 218, label: 'Togo', code: 'TG', dial: '+228' },
    { no: 219, label: 'Tokelau', code: 'TK', dial: '+690' },
    { no: 220, label: 'Tonga', code: 'TO', dial: '+676' },
    { no: 221, label: 'Trinidad and Tobago', code: 'TT', dial: '+1868' },
    { no: 222, label: 'Tunisia', code: 'TN', dial: '+216' },
    { no: 223, label: 'Turkey', code: 'TR', dial: '+90' },
    { no: 224, label: 'Turkmenistan', code: 'TM', dial: '+993' },
    { no: 225, label: 'Turks and Caicos Islands', code: 'TC', dial: '+1649' },
    { no: 226, label: 'Tuvalu', code: 'TV', dial: '+688' },
    { no: 227, label: 'U.S. Virgin Islands', code: 'VI', dial: '+1340' },
    { no: 228, label: 'Uganda', code: 'UG', dial: '+256' },
    { no: 229, label: 'Ukraine', code: 'UA', dial: '+380' },
    { no: 230, label: 'United Arab Emirates', code: 'AE', dial: '+971' },
    { no: 231, label: 'United Kingdom', code: 'GB', dial: '+44' },
    { no: 232, label: 'United States', code: 'US', dial: '+1' },
    { no: 233, label: 'Uruguay', code: 'UY', dial: '+598' },
    { no: 234, label: 'Uzbekistan', code: 'UZ', dial: '+998' },
    { no: 235, label: 'Vanuatu', code: 'VU', dial: '+678' },
    { no: 236, label: 'Vatican', code: 'VA', dial: '+379' },
    { no: 237, label: 'Venezuela', code: 'VE', dial: '+58' },
    { no: 238, label: 'Vietnam', code: 'VN', dial: '+84' },
    { no: 239, label: 'Wallis and Futuna', code: 'WF', dial: '+681' },
    { no: 240, label: 'Western Sahara', code: 'EH', dial: '+212' },
    { no: 241, label: 'Yemen', code: 'YE', dial: '+967' },
    { no: 242, label: 'Zambia', code: 'ZM', dial: '+260' },
    { no: 243, label: 'Zimbabwe', code: 'ZW', dial: '+263' },

];
// const Countries = [
//     { no: 1, label: 'Afghanistan', code: 'AF', dial: '+93' },
//     { no: 2, label: 'Albania', code: 'AL', dial: '+355' },
//     { no: 3, label: 'Algeria', code: 'DZ', dial: '+213' },
//     // { no: 4, label: 'American Samoa', code: 'AS', dial: '+1684' },
//     { no: 5, label: 'Andorra', code: 'AD', dial: '+376' },
//     { no: 6, label: 'Angola', code: 'AO', dial: '+244' },
//     // { no: 7, label: 'Anguilla', code: 'AI', dial: '+1264' },
//     { no: 8, label: 'Antarctica', code: 'AQ', dial: '+672' },
//     // { no: 9, label: 'Antigua and Barbuda', code: 'AG', dial: '+1268' },
//     { no: 10, label: 'Argentina', code: 'AR', dial: '+54' },
//     { no: 11, label: 'Armenia', code: 'AM', dial: '+374' },
//     // { no: 12, label: 'Aruba', code: 'AW', dial: '+297' },
//     { no: 13, label: 'Australia', code: 'AU', dial: '+61' },
//     { no: 14, label: 'Austria', code: 'AT', dial: '+43' },
//     { no: 15, label: 'Azerbaijan', code: 'AZ', dial: '+994' },
//     // { no: 16, label: 'Bahamas', code: 'BS', dial: '+1242' },
//     // { no: 17, label: 'Bahrain', code: 'BH', dial: '+973' },
//     { no: 18, label: 'Bangladesh', code: 'BD', dial: '+880' },
//     // { no: 19, label: 'Barbados', code: 'BB', dial: '+1246' },
//     { no: 20, label: 'Belarus', code: 'BY', dial: '+375' },
//     { no: 21, label: 'Belgium', code: 'BE', dial: '+32' },
//     // { no: 22, label: 'Belize', code: 'BZ', dial: '+501' },
//     // { no: 23, label: 'Benin', code: 'BJ', dial: '+229' },
//     // { no: 24, label: 'Bermuda', code: 'BM', dial: '+1441' },
//     // { no: 25, label: 'Bhutan', code: 'BT', dial: '+975' },
//     { no: 26, label: 'Bolivia', code: 'BO', dial: '+591' },
//     // { no: 27, label: 'Bosnia and Herzegovina', code: 'BA', dial: '+387' },
//     // { no: 28, label: 'Botswana', code: 'BW', dial: '+267' },
//     { no: 29, label: 'Brazil', code: 'BR', dial: '+55' },
//     // { no: 30, label: 'British Indian Ocean Territory', code: 'IO', dial: '+246' },
//     // { no: 31, label: 'British Virgin Islands', code: 'VG', dial: '+1284' },
//     { no: 32, label: 'Brunei', code: 'BN', dial: '+673' },
//     { no: 33, label: 'Bulgaria', code: 'BG', dial: '+359' },
//     // { no: 34, label: 'Burkina Faso', code: 'BF', dial: '+226' },
//     // { no: 35, label: 'Burundi', code: 'BI', dial: '+257' },
//     { no: 36, label: 'Cambodia', code: 'KH', dial: '+855' },
//     // { no: 37, label: 'Cameroon', code: 'CM', dial: '+237' },
//     { no: 38, label: 'Canada', code: 'CA', dial: '+1' },
//     // { no: 39, label: 'Cape Verde', code: 'CV', dial: '+238' },
//     // { no: 40, label: 'Cayman Islands', code: 'KY', dial: '+1345' },
//     { no: 41, label: 'Central African Republic', code: 'CF', dial: '+236' },
//     // { no: 42, label: 'Chad', code: 'TD', dial: '+235' },
//     { no: 43, label: 'Chile', code: 'CL', dial: '+56' },
//     { no: 44, label: 'China', code: 'CN', dial: '+86' },
//     // { no: 45, label: 'Christmas Island', code: 'CX', dial: '+61' },
//     // { no: 46, label: 'Cocos Islands', code: 'CC', dial: '+61' },
//     { no: 47, label: 'Colombia', code: 'CO', dial: '+57' },
//     // { no: 48, label: 'Comoros', code: 'KM', dial: '+269' },
//     // { no: 49, label: 'Cook Islands', code: 'CK', dial: '+682' },
//     { no: 50, label: 'Costa Rica', code: 'CR', dial: '+506' },
//     { no: 51, label: 'Croatia', code: 'HR', dial: '+385' },
//     // { no: 52, label: 'Cuba', code: 'CU', dial: '+53' },
//     // { no: 53, label: 'Curacao', code: 'CW', dial: '+599' },
//     // { no: 54, label: 'Cyprus', code: 'CY', dial: '+357' },
//     { no: 55, label: 'Czech Republic', code: 'CZ', dial: '+420' },
//     { no: 56, label: 'Democratic Republic of the Congo', code: 'CD', dial: '+243' },
//     { no: 57, label: 'Denmark', code: 'DK', dial: '+45' },
//     // { no: 58, label: 'Djibouti', code: 'DJ', dial: '+253' },
//     // { no: 59, label: 'Dominica', code: 'DM', dial: '+1767' },
//     // { no: 60, label: 'Dominican Republic', code: 'DO', dial: '+1809' },
//     // { no: 61, label: 'Dominican Republic', code: 'DO', dial: '+1829' },
//     // { no: 62, label: 'Dominican Republic', code: 'DO', dial: '+1849' },
//     // { no: 63, label: 'East Timor', code: 'TL', dial: '+670' },
//     // { no: 64, label: 'Ecuador', code: 'EC', dial: '+593' },
//     { no: 65, label: 'Egypt', code: 'EG', dial: '+20' },
//     // { no: 66, label: 'El Salvador', code: 'SV', dial: '+503' },
//     // { no: 67, label: 'Equatorial Guinea', code: 'GQ', dial: '+240' },
//     // { no: 68, label: 'Eritrea', code: 'ER', dial: '+291' },
//     // { no: 69, label: 'Estonia', code: 'EE', dial: '+372' },
//     // { no: 70, label: 'Ethiopia', code: 'ET', dial: '+251' },
//     // { no: 71, label: 'Falkland Islands', code: 'FK', dial: '+500' },
//     // { no: 72, label: 'Faroe Islands', code: 'FO', dial: '+298' },
//     // { no: 73, label: 'Fiji', code: 'FJ', dial: '+679' },
//     { no: 74, label: 'Finland', code: 'FI', dial: '+358' },
//     { no: 75, label: 'France', code: 'FR', dial: '+33' },
//     // { no: 76, label: 'French Polynesia', code: 'PF', dial: '+689' },
//     // { no: 77, label: 'Gabon', code: 'GA', dial: '+241' },
//     // { no: 78, label: 'Gambia', code: 'GM', dial: '+220' },
//     { no: 79, label: 'Georgia', code: 'GE', dial: '+995' },
//     { no: 80, label: 'Germany', code: 'DE', dial: '+49' },
//     // { no: 81, label: 'Ghana', code: 'GH', dial: '+233' },
//     // { no: 82, label: 'Gibraltar', code: 'GI', dial: '+350' },
//     { no: 83, label: 'Greece', code: 'GR', dial: '+30' },
//     // { no: 84, label: 'Greenland', code: 'GL', dial: '+299' },
//     // { no: 85, label: 'Grenada', code: 'GD', dial: '+1473' },
//     // { no: 86, label: 'Guam', code: 'GU', dial: '+1671' },
//     // { no: 87, label: 'Guatemala', code: 'GT', dial: '+502' },
//     // { no: 88, label: 'Guernsey', code: 'GG', dial: '+441481' },
//     // { no: 89, label: 'Guinea', code: 'GN', dial: '+224' },
//     // { no: 90, label: 'Guinea-Bissau', code: 'GW', dial: '+245' },
//     // { no: 91, label: 'Guyana', code: 'GY', dial: '+592' },
//     // { no: 92, label: 'Haiti', code: 'HT', dial: '+509' },
//     // { no: 93, label: 'Honduras', code: 'HN', dial: '+504' },
//     { no: 94, label: 'Hong Kong', code: 'HK', dial: '+852' },
//     { no: 95, label: 'Hungary', code: 'HU', dial: '+36' },
//     { no: 96, label: 'Iceland', code: 'IS', dial: '+354' },
//     { no: 97, label: 'India', code: 'IN', dial: '+91' },
//     { no: 98, label: 'Indonesia', code: 'ID', dial: '+62' },
//     { no: 99, label: 'Iran', code: 'IR', dial: '+98' },
//     { no: 100, label: 'Iraq', code: 'IQ', dial: '+964' },
//     { no: 101, label: 'Ireland', code: 'IE', dial: '+353' },
//     // { no: 102, label: 'Isle of Man', code: 'IM', dial: '+441624' },
//     { no: 103, label: 'Israel', code: 'IL', dial: '+972' },
//     { no: 104, label: 'Italy', code: 'IT', dial: '+39' },
//     // { no: 105, label: 'Ivory Coast', code: 'CI', dial: '+225' },
//     // { no: 106, label: 'Jamaica', code: 'JM', dial: '+1876' },
//     { no: 107, label: 'Japan', code: 'JP', dial: '+81' },
//     // { no: 108, label: 'Jersey', code: 'JE', dial: '+441534' },
//     // { no: 109, label: 'Jordan', code: 'JO', dial: '+962' },
//     // { no: 110, label: 'Kazakhstan', code: 'KZ', dial: '+7' },
//     // { no: 111, label: 'Kenya', code: 'KE', dial: '+254' },
//     // { no: 112, label: 'Kiribati', code: 'KI', dial: '+686' },
//     // { no: 113, label: 'Kosovo', code: 'XK', dial: '+383' },
//     // { no: 114, label: 'Kuwait', code: 'KW', dial: '+965' },
//     // { no: 115, label: 'Kyrgyzstan', code: 'KG', dial: '+996' },
//     { no: 116, label: 'Laos', code: 'LA', dial: '+856' },
//     // { no: 117, label: 'Latvia', code: 'LV', dial: '+371' },
//     // { no: 118, label: 'Lebanon', code: 'LB', dial: '+961' },
//     // { no: 119, label: 'Lesotho', code: 'LS', dial: '+266' },
//     // { no: 120, label: 'Liberia', code: 'LR', dial: '+231' },
//     // { no: 121, label: 'Libya', code: 'LY', dial: '+218' },
//     // { no: 122, label: 'Liechtenstein', code: 'LI', dial: '+423' },
//     { no: 123, label: 'Lithuania', code: 'LT', dial: '+370' },
//     { no: 124, label: 'Luxembourg', code: 'LU', dial: '+352' },
//     { no: 125, label: 'Macau', code: 'MO', dial: '+853' },
//     // { no: 126, label: 'Macedonia', code: 'MK', dial: '+389' },
//     // { no: 127, label: 'Madagascar', code: 'MG', dial: '+261' },
//     // { no: 128, label: 'Malawi', code: 'MW', dial: '+265' },
//     { no: 129, label: 'Malaysia', code: 'MY', dial: '+60' },
//     { no: 130, label: 'Maldives', code: 'MV', dial: '+960' },
//     // { no: 131, label: 'Mali', code: 'ML', dial: '+223' },
//     // { no: 132, label: 'Malta', code: 'MT', dial: '+356' },
//     // { no: 133, label: 'Marshall Islands', code: 'MH', dial: '+692' },
//     // { no: 134, label: 'Mauritania', code: 'MR', dial: '+222' },
//     // { no: 135, label: 'Mauritius', code: 'MU', dial: '+230' },
//     // { no: 136, label: 'Mayotte', code: 'YT', dial: '+262' },
//     { no: 137, label: 'Mexico', code: 'MX', dial: '+52' },
//     // { no: 138, label: 'Micronesia', code: 'FM', dial: '+691' },
//     { no: 139, label: 'Moldova', code: 'MD', dial: '+373' },
//     { no: 140, label: 'Monaco', code: 'MC', dial: '+377' },
//     { no: 141, label: 'Mongolia', code: 'MN', dial: '+976' },
//     // { no: 142, label: 'Montenegro', code: 'ME', dial: '+382' },
//     // { no: 143, label: 'Montserrat', code: 'MS', dial: '+1664' },
//     { no: 144, label: 'Morocco', code: 'MA', dial: '+212' },
//     // { no: 145, label: 'Mozambique', code: 'MZ', dial: '+258' },
//     { no: 146, label: 'Myanmar', code: 'MM', dial: '+95' },
//     // { no: 147, label: 'Namibia', code: 'NA', dial: '+264' },
//     // { no: 148, label: 'Nauru', code: 'NR', dial: '+674' },
//     // { no: 149, label: 'Nepal', code: 'NP', dial: '+977' },
//     { no: 150, label: 'Netherlands', code: 'NL', dial: '+31' },
//     // { no: 151, label: 'Netherlands Antilles', code: 'AN', dial: '+599' },
//     // { no: 152, label: 'New Caledonia', code: 'NC', dial: '+687' },
//     { no: 153, label: 'New Zealand', code: 'NZ', dial: '+64' },
//     // { no: 154, label: 'Nicaragua', code: 'NI', dial: '+505' },
//     // { no: 155, label: 'Niger', code: 'NE', dial: '+227' },
//     // { no: 156, label: 'Nigeria', code: 'NG', dial: '+234' },
//     { no: 157, label: 'Niue', code: 'NU', dial: '+683' },
//     { no: 158, label: 'North Korea', code: 'KP', dial: '+850' },
//     // { no: 159, label: 'Northern Mariana Islands', code: 'MP', dial: '+1670' },
//     { no: 160, label: 'Norway', code: 'NO', dial: '+47' },
//     { no: 161, label: 'Oman', code: 'OM', dial: '+968' },
//     { no: 162, label: 'Pakistan', code: 'PK', dial: '+92' },
//     { no: 163, label: 'Palau', code: 'PW', dial: '+680' },
//     { no: 164, label: 'Palestine', code: 'PS', dial: '+970' },
//     // { no: 165, label: 'Panama', code: 'PA', dial: '+507' },
//     // { no: 166, label: 'Papua New Guinea', code: 'PG', dial: '+675' },
//     // { no: 167, label: 'Paraguay', code: 'PY', dial: '+595' },
//     { no: 168, label: 'Peru', code: 'PE', dial: '+51' },
//     { no: 169, label: 'Philippines', code: 'PH', dial: '+63' },
//     // { no: 170, label: 'Pitcairn', code: 'PN', dial: '+64' },
//     { no: 171, label: 'Poland', code: 'PL', dial: '+48' },
//     { no: 172, label: 'Portugal', code: 'PT', dial: '+351' },
//     { no: 173, label: 'Puerto Rico', code: 'PR', dial: '+1787' },
//     { no: 174, label: 'Puerto Rico', code: 'PR', dial: '+1939' },
//     { no: 175, label: 'Qatar', code: 'QA', dial: '+974' },
//     { no: 176, label: 'Republic of the Congo', code: 'CG', dial: '+242' },
//     // { no: 177, label: 'Reunion', code: 'RE', dial: '+262' },
//     { no: 178, label: 'Romania', code: 'RO', dial: '+40' },
//     { no: 179, label: 'Russia', code: 'RU', dial: '+7' },
//     // { no: 180, label: 'Rwanda', code: 'RW', dial: '+250' },
//     // { no: 181, label: 'Saint Barthelemy', code: 'BL', dial: '+590' },
//     // { no: 182, label: 'Saint Helena', code: 'SH', dial: '+290' },
//     // { no: 183, label: 'Saint Kitts and Nevis', code: 'KN', dial: '+1869' },
//     // { no: 184, label: 'Saint Lucia', code: 'LC', dial: '+1758' },
//     // { no: 185, label: 'Saint Martin', code: 'MF', dial: '+590' },
//     // { no: 186, label: 'Saint Pierre and Miquelon', code: 'PM', dial: '+508' },
//     // { no: 187, label: 'Saint Vincent and the Grenadines', code: 'VC', dial: '+1784' },
//     // { no: 188, label: 'Samoa', code: 'WS', dial: '+685' },
//     // { no: 189, label: 'San Marino', code: 'SM', dial: '+378' },
//     // { no: 190, label: 'Sao Tome and Principe', code: 'ST', dial: '+239' },
//     { no: 191, label: 'Saudi Arabia', code: 'SA', dial: '+966' },
//     // { no: 192, label: 'Senegal', code: 'SN', dial: '+221' },
//     // { no: 193, label: 'Serbia', code: 'RS', dial: '+381' },
//     // { no: 194, label: 'Seychelles', code: 'SC', dial: '+248' },
//     // { no: 195, label: 'Sierra Leone', code: 'SL', dial: '+232' },
//     { no: 196, label: 'Singapore', code: 'SG', dial: '+65' },
//     // { no: 197, label: 'Sint Maarten', code: 'SX', dial: '+1721' },
//     { no: 198, label: 'Slovakia', code: 'SK', dial: '+421' },
//     { no: 199, label: 'Slovenia', code: 'SI', dial: '+386' },
//     // { no: 200, label: 'Solomon Islands', code: 'SB', dial: '+677' },
//     // { no: 201, label: 'Somalia', code: 'SO', dial: '+252' },
//     { no: 202, label: 'South Africa', code: 'ZA', dial: '+27' },
//     { no: 203, label: 'South Korea', code: 'KR', dial: '+82' },
//     // { no: 204, label: 'South Sudan', code: 'SS', dial: '+211' },
//     { no: 205, label: 'Spain', code: 'ES', dial: '+34' },
//     // { no: 206, label: 'Sri Lanka', code: 'LK', dial: '+94' },
//     // { no: 207, label: 'Sudan', code: 'SD', dial: '+249' },
//     // { no: 208, label: 'Suriname', code: 'SR', dial: '+597' },
//     // { no: 209, label: 'Svalbard and Jan Mayen', code: 'SJ', dial: '+47' },
//     // { no: 210, label: 'Swaziland', code: 'SZ', dial: '+268' },
//     { no: 211, label: 'Sweden', code: 'SE', dial: '+46' },
//     { no: 212, label: 'Switzerland', code: 'CH', dial: '+41' },
//     // { no: 213, label: 'Syria', code: 'SY', dial: '+963' },
//     // { no: 214, label: 'Taiwan', code: 'TW', dial: '+886' },
//     // { no: 215, label: 'Tajikistan', code: 'TJ', dial: '+992' },
//     // { no: 216, label: 'Tanzania', code: 'TZ', dial: '+255' },
//     { no: 217, label: 'Thailand', code: 'TH', dial: '+66' },
//     // { no: 218, label: 'Togo', code: 'TG', dial: '+228' },
//     // { no: 219, label: 'Tokelau', code: 'TK', dial: '+690' },
//     // { no: 220, label: 'Tonga', code: 'TO', dial: '+676' },
//     // { no: 221, label: 'Trinidad and Tobago', code: 'TT', dial: '+1868' },
//     // { no: 222, label: 'Tunisia', code: 'TN', dial: '+216' },
//     { no: 223, label: 'Turkey', code: 'TR', dial: '+90' },
//     // { no: 224, label: 'Turkmenistan', code: 'TM', dial: '+993' },
//     // { no: 225, label: 'Turks and Caicos Islands', code: 'TC', dial: '+1649' },
//     // { no: 226, label: 'Tuvalu', code: 'TV', dial: '+688' },
//     // { no: 227, label: 'U.S. Virgin Islands', code: 'VI', dial: '+1340' },
//     // { no: 228, label: 'Uganda', code: 'UG', dial: '+256' },
//     // { no: 229, label: 'Ukraine', code: 'UA', dial: '+380' },
//     { no: 230, label: 'United Arab Emirates', code: 'AE', dial: '+971' },
//     { no: 231, label: 'United Kingdom', code: 'GB', dial: '+44' },
//     { no: 232, label: 'United States', code: 'US', dial: '+1' },
//     // { no: 233, label: 'Uruguay', code: 'UY', dial: '+598' },
//     // { no: 234, label: 'Uzbekistan', code: 'UZ', dial: '+998' },
//     // { no: 235, label: 'Vanuatu', code: 'VU', dial: '+678' },
//     // { no: 236, label: 'Vatican', code: 'VA', dial: '+379' },
//     // { no: 237, label: 'Venezuela', code: 'VE', dial: '+58' },
//     // { no: 238, label: 'Vietnam', code: 'VN', dial: '+84' },
//     // { no: 239, label: 'Wallis and Futuna', code: 'WF', dial: '+681' },
//     // { no: 240, label: 'Western Sahara', code: 'EH', dial: '+212' },
//     // { no: 241, label: 'Yemen', code: 'YE', dial: '+967' },
//     // { no: 242, label: 'Zambia', code: 'ZM', dial: '+260' },
//     // { no: 243, label: 'Zimbabwe', code: 'ZW', dial: '+263' },

// ];

export default Countries