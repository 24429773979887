import logo from '../assets/icons/Tablepointer Logo-White.svg';


function Header() {
  return (
    <div className='flex flex-col text-left p-[40px] text-white md:pt-60'>
      <img src={logo} alt='Tablepointer' />
      <h1 className='text-[50px] text-white leading-[1.15] my-[10px]'>Energy Savings Evaluator</h1>
      <h3>Estimate your savings potential of your F&B outlets with Tablepointer!</h3>
    </div>
  )
}

export default Header